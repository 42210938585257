import { ChatService } from '../services/chat-service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ChatroomGuard implements CanActivate {
  constructor(
    private chatService: ChatService,
    private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (this.chatService.checkConnection()) {
      return true;
    } else {
      this.router.navigate(['']);
      return false;
    }
  }
}
