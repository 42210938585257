import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from '../../../environments/environment';
import { catchError, tap, switchAll, share, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, Subject } from 'rxjs';
export const url = environment.socketUrl;

@Injectable()
export class ChatService {
  private socket$: WebSocketSubject<any>;
  private messagesSubject$ = new Subject();
  public idSubject$ = new BehaviorSubject(1);
  public messages$ = this.messagesSubject$.pipe(switchAll(), catchError(e => { throw e; }), shareReplay(1));

  public connect(): void {

    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = this.getNewWebSocket();
      const messages = this.socket$.pipe(
        tap({
          error: error => console.log(error),
        }),
        catchError(_ => EMPTY)
      );
      this.messagesSubject$.next(messages);
    }
  }

  private getNewWebSocket(): WebSocketSubject<any> {
    return webSocket(url);
  }

  checkConnection(): boolean {
    return !(!this.socket$ || this.socket$.closed);
  }
  sendMessage(msg: any): void {
    this.socket$.next(msg);
  }

  close(): void {
    this.socket$?.complete();
    this.socket$ = undefined;
  }
}
